import React from "react";

export default function PromoBaner() {
  return (
    <>
      <div className="promoBaner">
        <h2>Promocja w Gen Factor!</h2>
        <p>Zniżka na produkty z serii Retinoid! Aż -50% mniej!</p>
      </div>
      <div className="promoBaner-alt">
        <h2>10% zniżki na hydrożele</h2>
        <p>Gen Factor No 05, Gen Factor No 06, Gen Factor No 07, Gen Factor No 08, Gen Factor No 09 dostępne są w cenie niższej o 10%. Ceny, które zobaczysz po zalogowaniu, są już cenami po obniżce.</p>
      </div>
    </>
  );
}
